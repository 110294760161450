<template>
    <div class="search_filter_wrap mb50">
        <div class="search_item_wrap">
            <div class="filter_category_box">
                <el-select id="category" v-model="searchInputOption" class="my_filter select"
                           popper-class="category_select"
                           :popper-append-to-body="false">
                    <el-option :value="'ti'" :label="$t('subject')"></el-option>
                    <el-option :value="'ni'" :label="$t('owner')"></el-option>
                </el-select>
            </div>
            <div class="search_input">
                <el-input :placeholder="$t('st')" v-model="searchInput"
                          @input="setData('searchInput',searchInput)"
                          @keypress.native.enter="setFilterFunc(searchInputOption,searchInput)"></el-input>
                <button class="search_btn" @click="setFilterFunc(searchInputOption,searchInput)">
                    <span>{{ $t('search') }}</span>
                    <img src="@/assets/image/main/search_btn.png"></button>
            </div>
            <div class="end_filter">
                <el-popover
                        popper-class="top_500"
                        placement=""
                        width="435"
                        trigger="click"
                        :open-delay="300"
                        :offset="0"
                        :append-to-body="false"
                        :popper-options="{
                          boundariesElement: 'body',
                          gpuAcceleration: true,
                          positionFixed: true,
                          preventOverflow: true,
                        }">
                    <el-button slot="reference" class="filter_btn two"></el-button>
                    <div class="popup_tail"></div>
                    <div class="popup_bg">
                        <div class="popup_inner">
                            <div class="inner_top"><span class="title">{{ $t('filter') }}</span></div>
                            <div class="inner_middle">
                                <div class="sub_title">{{ $t('all_sell_price') }}</div>
                                <div class="price_wrap">
                                    <el-input v-model="filterPrice[0]" class="el_price_input el_input_num"
                                              type="text" @keypress.native.enter="setFilterFunc('tot', filterPrice)"
                                              oninput="value = value.replace(/[^0-9.]/g,'')"
                                    />
                                    <span> Mg</span>
                                    <span class="first_input_step">~</span>
                                    <el-input v-model="filterPrice[1]" class="el_price_input el_input_num"
                                              type="text" @keypress.native.enter="setFilterFunc('tot', filterPrice)"
                                              oninput="value = value.replace(/[^0-9.]/g,'')"
                                    />
                                    <span> Mg</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="wrapper_bottom" @click="setFilterFunc('tot', filterPrice)">
                        <button>{{ $t('apply2') }}</button>
                    </div>
                </el-popover>
            </div>
        </div>
    </div>
</template>

<script>
import util from "@/mixins/util";
import collectUtil from "@/components/collect/collectUtil";

const {filterConfig} = collectUtil
export default {
    name: "CollectFilterMyPageLayout",
    mixins: [collectUtil],
    components: {},
    inject: ['setFilter', 'setSort'],
    provide() {
        return {}
    },
    props: {
        filterData: {
            default: () => {
            }
        },
    },
    data() {
        return {
            sortOption: 'i.updated_at',
            searchInputOption: 'ti',
            searchInput: '',
            filterPrice: ["", ""],
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.setterFilterDataInData();
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        setterFilterDataInData() {
            let classnames = Object.keys(this.filterData);
            classnames.forEach(classname => {
                let obj = this.filterData[classname];
                let type = filterConfig[obj.key].type;
                if (this.hasOwnProperty(classname)) {
                    if (type == 'query') {
                        this[classname] = obj.query;
                        if (this.hasOwnProperty(classname + 'Option')) {
                            this[classname + 'Option'] = obj.key;
                        }
                    } else if (type === 'between') {
                        this[classname] = [obj.start, obj.end];
                    } else if (type === 'match') {
                        if (isNaN(obj.value)) {
                            this[classname] = obj.value;
                        } else {
                            this[classname] = Number(obj.value);
                        }

                    } else if (type === 'terms') {
                        this[classname] = obj.terms;
                    }

                }
            })
        },
        setFilterFunc(key, value) {
            this.setFilter(key, value);
        },
        setData(key, value) {
            this[key] = value;
        },
        changeDateFilter() {
            if (util.isEmpty(this.filterDate)) {
                this.setFilter('cr', '');
            } else {
                let date = this.filterDate;
                date[0] = `${date[0]} 00:00:00`;
                date[1] = `${date[1]} 23:59:59`;
                this.setFilter('cr', date);
            }
        },
        openModal(name) {
            this.$modal.show(name);
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>